<template>
  <div class="flex items-center justify-between gap-x-1">
    <router-link class="go_back_button group" :to="backSideLink" v-if="backSideLinkCriteria">
      <GoBackIcon />
      <span class="go_back_button__text">Go Back to Select Vehicle</span>
    </router-link>
    <span v-if="backSideLinkCriteria" class="mt-6 flex gap-1 text-black dark:text-white">
      Step
      <p class="text-main">3</p>
      of 5</span
    >
  </div>
  <div
    v-if="modalVisible"
    @click="closeModal"
    class="defaultModal fixed inset-0 z-40 flex items-center justify-center bg-gray-900 bg-opacity-70 p-4"
  >
    <div @click.stop class="wrapper h-max w-full max-w-2xl rounded-lg">
      <div class="flex justify-end p-4">
        <button
          @click="closeModal"
          aria-label="close"
          class="closeButton inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400"
          type="button"
        >
          <svg
            class="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              fill-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <div class="p-6 pt-0">
        <b>Important Notice:</b><br />The price has been updated. Click on OK below to see the new
        offer.
      </div>
      <div class="border-t border-gray-600 p-6">
        <button
          @click="closeModal"
          type="button"
          class="bg-main text-background rounded-[37px] px-5 py-2.5 text-center"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
  <div class="">
    <div
      class="flex flex-row flex-wrap items-center justify-between px-4"
      :class="!backSideLinkCriteria ? 'py-[40px] pb-[10px]' : ''"
    >
      <h1 class="title mr-[15px]">Payment</h1>
      <h1 class="title payment__price">{{ orderData.total }} EUR</h1>
    </div>
    <SummaryInfo
      classnames="summary_title_button_wrapper form_to_edit"
      title="Book Now the Following Ride"
      disabled="true"
    />
  </div>
  <div class="flex flex-col items-center justify-between">
    <div class="w-full">
      <p id="time_over" style="display: none" class="payment_timer_text text-error">
        The time to book this ride on line has expired, but no worries, our consultants will reach
        out to you with an updated offer in due course.
      </p>
      <form id="payment-form" class="payment_form form mt-[-38px]">
        <div id="payment-element">
          <!--Stripe.js injects the Payment Element-->
        </div>
        <label class="relative mt-[28px] inline-flex w-full cursor-pointer items-start">
          <input
            type="checkbox"
            value=""
            class="peer sr-only"
            v-model="user_add_value"
            true-value="1"
            false-value="0"
            @change="changeCheckbox"
          />
          <div
            class="bg-toggler_gray peer-checked:bg-main peer h-6 w-[39px] min-w-[39px] rounded-full after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-[80%] peer-checked:after:border-white peer-checked:after:bg-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 md:h-[31px] md:w-[51px] md:after:h-[27px] md:after:w-[27px] md:peer-checked:after:translate-x-[70%]"
          ></div>
          <span class="account_toggler_text"
            >Create Personal Account After Payment, it will facilitate you a lot if you book other
            services, and it requires no further data entry, only 1 click!</span
          >
        </label>
        <textarea
          ref="textarea"
          v-model="notes"
          maxlength="230"
          :class="noteError ? 'error' : ''"
          class="input ym-record-keys mb-0 mt-[30px] w-full resize-none overflow-hidden"
          rows="1"
          placeholder="Here you can leave any notes…"
        ></textarea>
        <p v-if="noteError" class="ml-1 mt-2 inline-block text-sm font-extralight text-[#df1b41]">
          {{ noteError }}
        </p>

        <div class="next_step_button_wrapper mt-[40px]">
          <button class="next_step_button" id="submit">Pay now</button>
        </div>
        <p id="payment-message" class="payment_timer_text hidden"></p>
        <!-- Time Counter start -->

        <div
          :class="!countdownTimer ? 'hidden' : ''"
          id="countdown_wrap"
          class="payment_timer_text mt-6 xl:mt-10"
        >
          <PaymentTimer
            v-if="orderData.countdown || timerExpires"
            :initial_time="orderData.countdown"
            :timerExpires="timerExpires"
          />
        </div>
        <!--  Time Counter end -->
      </form>
      <div
        v-if="orderData.timer_expires"
        class="defaultModal fixed inset-0 z-40 flex items-center justify-center bg-gray-900 bg-opacity-70 p-4"
      >
        <div @click.stop class="wrapper h-max w-full max-w-2xl rounded-lg">
          <div class="flex justify-center p-4">Payment timer expired. Please refill</div>
          <div class="p-6 pt-0">
            <button class="modal_refill_button" @click="router.push({ name: 'home' })">
              Fill out again
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import SummaryInfo from '@/components/SummaryInfo.vue'
import GoBackIcon from '@/components/icons/GoBackIcon.vue'
import PaymentTimer from '@/components/PaymentTimer.vue'
import * as Sentry from '@sentry/browser'
import { onMounted, onBeforeMount, inject, ref, computed, watch, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useOrderStore } from '@/stores/order'
import { useMainStore } from '@/stores/main'
import { useCarsStore } from '@/stores/cars'
import * as yup from 'yup'

const router = useRouter()
const route = useRoute()
const mainStore = useMainStore()
const orderStore = useOrderStore()
const carsStore = useCarsStore()

const storage = inject('storage')
const axios = inject('axios')

const { orderData, orderId } = storeToRefs(orderStore)
const { paymentVisited, priceChanged, isRequesting, mode } = storeToRefs(mainStore)

const textarea = ref(null)
const noteError = ref(false)
const countdownTimer = ref(orderData.value.countdown)
const timerExpires = ref(false)

let user_add_value = 0
storage.setItem('user_add', user_add_value, 'session')

const backSideLink = ref('/vehicle')

const colors = {
  dli: {
    '.Input:hover': '1px solid #5FD052',
    '.Input:active': '1px solid #5FD052'
  },
  rlt: {
    '.Input:hover': '1px solid #c6a65f',
    '.Input:active': '1px solid #c6a65f'
  },
  dgt: {
    '.Input:hover': '1px solid #e5ba8c',
    '.Input:active': '1px solid #e5ba8c'
  }
}

const inputHover = computed(() => {
  return colors[import.meta.env.VITE_PROJECT_ALIAS]['.Input:hover']
})
const inputActive = computed(() => {
  return colors[import.meta.env.VITE_PROJECT_ALIAS]['.Input:active']
})

const backSideLinkCriteria = computed(() => {
  return !!orderData.value.fromStart
})
const modalView = ref(false)

function closeModal() {
  modalView.value = false
}

const modalVisible = computed(() => {
  return priceChanged.value && modalView.value
})

function changeCheckbox() {
  console.log(user_add_value)
  storage.setItem('user_add', user_add_value, 'session')
}

function initPayment(paymentData) {
  // This is a public sample test API key.
  // Don't submit any personally identifiable information in requests made with this key.
  // Sign in to see your own test API key embedded in code samples.
  // eslint-disable-next-line
  const stripe = Stripe(import.meta.env.VITE_APP_STRIPE_KEY, {
    // eslint-disable-line
    locale: 'en',
    hidePostalCode: true
  })
  const appearanceModes = {
    light: {
      rules: {
        '.Input': {
          color: '#2B2D32',
          border: '1px solid #878787',
          borderRadius: '53px',
          padding: '22px 20px',
          backgroundColor: '#E8EDE880'
        },
        '.Input::placeholder': {
          color: '#878787'
        },
        '.Input:hover': {
          border: inputHover.value
        },
        '.Input:active': {
          border: inputActive.value
        },
        '.Input:focus': {
          boxShadow: 'none',
          borderColor: 'black'
        },
        '.Label': {
          opacity: '0',
          visibility: 'hidden',
          width: '0',
          height: '0',
          display: 'none !important'
        },
        '.Input--invalid': {
          boxShadow: 'none',
          color: '#FA4141',
          borderColor: '#FA4141'
        },
        '.p-FieldError': {
          color: '#FA4141'
        }

        // See all supported class names and selector syntax below
      }
    },
    dark: {
      rules: {
        '.Input': {
          color: '#FFFFFF',
          border: '1px solid #3D4043',
          borderRadius: '53px',
          padding: '22px 20px',
          backgroundColor: '#2B2D32'
        },
        '.Input::placeholder': {
          color: '#878787'
        },
        '.Input:hover': {
          border: inputHover.value
        },
        '.Input:active': {
          border: inputActive.value
        },
        '.Input:focus': {
          boxShadow: 'none',
          borderColor: 'black'
        },
        '.Label': {
          opacity: '0',
          visibility: 'hidden',
          width: '0',
          height: '0',
          display: 'none !important'
        },
        '.Input--invalid': {
          boxShadow: 'none',
          color: '#FA4141',
          borderColor: '#FA4141'
        }

        // See all supported class names and selector syntax below
      }
    }
  }
  // The items the customer wants to buy
  // const items = {
  //   amount: orderData.value.total,
  //   email: orderData.value.email,
  //   website: import.meta.env.VITE_PROJECT_URL
  // }

  let elements

  initialize(paymentData)
  checkStatus()

  document.querySelector('#payment-form').addEventListener('submit', handleSubmit)

  // Fetches a payment intent and captures the client secret\
  async function initialize(paymentData) {
    const clientSecret = paymentData.client_secret
    elements = stripe.elements({ clientSecret, appearance: appearanceModes[mode.value] })
    const paymentElement = elements.create('payment')
    paymentElement.mount('#payment-element')

    watch(mode, () => {
      elements.update({ appearance: appearanceModes[mode.value] })
    })

    setLoading(false)

    //   await axios
    //     .post('/payment', items)
    //     .then(function (response) {
    //       const clientSecret = response.data.data.clientSecret
    //       elements = stripe.elements({ clientSecret, appearance: appearanceModes[mode.value] })
    //       const paymentElement = elements.create('payment')
    //       paymentElement.mount('#payment-element')
    //
    //       setLoading(false)
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //     })
  }

  async function handleSubmit(e) {
    e.preventDefault()

    const contactSchema = yup
      .object({
        notes: yup.string().nullable().max(230, 'Notes should not exceed 230 character')
      })
      .validate({ notes: textarea.value.value })
      .then((res) => {
        orderStore.update({ notes: res.notes })
        confirm()
      })
      .catch((res) => {
        noteError.value = res.errors[0]
      })

    contactSchema

    async function confirm() {
      setLoading(true)
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: import.meta.env.VITE_APP_URL + '/success/payment_intent/' + orderId.value
        }
      })

      if (error.type === 'card_error' || error.type === 'validation_error') {
        showMessage(error.message)
      } else {
        showMessage('An unexpected error occurred.')
      }

      setLoading(false)
    }
  }

  // Fetches the payment intent status after payment submission
  async function checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (!clientSecret) {
      return
    }

    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)

    switch (paymentIntent.status) {
      case 'succeeded':
        showMessage('Payment succeeded!')
        mainStore.updatePaymentComplete(true)
        break
      case 'processing':
        showMessage('Your payment is processing.')
        break
      case 'requires_payment_method':
        showMessage('Your payment was not successful, please try again.')
        break
      default:
        showMessage('Something went wrong.')
        break
    }
  }

  function showMessage(messageText) {
    const messageContainer = document.querySelector('#payment-message')

    messageContainer.classList.remove('hidden')
    messageContainer.textContent = messageText

    setTimeout(function () {
      messageContainer.classList.add('hidden')
      messageContainer.textContent = ''
    }, 4000)
  }

  // Show a spinner on payment submission
  function setLoading(isLoading) {
    isRequesting.value = isLoading
    if (isRequesting.value) {
      // Disable the button and show a spinner
      if (document.querySelector('#submit')) {
        document.querySelector('#submit').disabled = true
      }
    } else {
      if (document.querySelector('#submit')) {
        document.querySelector('#submit').disabled = false
      }
    }
  }
}

onBeforeMount(async () => {
  let { transaction_id } = { ...route.params }
  isRequesting.value = true
  orderData.value.countdown = null

  if (transaction_id) {
    axios

      .post('transaction/init/' + transaction_id)
      .then(function (response) {
        if (response.data.status === 'error') {
          if (response.status == 202) {
            // If timer expires
            timerExpires.value = true
            orderStore.update({ timer_expires: true })
          }
        }

        if (response.data.status === 'success') {
          let countdown = null
          if (!response.data.data?.type && response.data?.data?.order?.payment_timer) {
            let datetime =
              new Date(
                new Date(response.data.data.order.payment_timer).toLocaleString('en-US')
              ).getTime() -
              3600000 +
              5 * 60000

            countdown = new Date(datetime)
          }

          let deal_id =
            response.data?.data.order.deal_id !== null
              ? response.data.data.order.deal_id.replace('n', '')
              : ''
          let contact_id =
            response.data?.data.order.contact_id !== null
              ? response.data.data.order.contact_id.replace('n', '')
              : ''

          let distance = response.data.data.order.distance

          if (!distance) {
            distance = orderData.value.distance
          }

          orderStore.update({
            ...response.data.data.order,
            deal_id: deal_id,
            contact_id: contact_id,
            date_start: response.data.data.order.date_start,
            distance: distance,
            countdown: countdown,
            fromStart: orderData.value.fromStart,
            transaction_id: transaction_id
          })

          countdownTimer.value = countdown

          orderData.value.allowedPages = {
            contact: 1,
            vehicle: 1,
            success_payment_intent: 0
          }

          if (response.data.data?.cars) {
            let selectedCar = response.data.data.cars.find(
              (x) => x.class_id === response.data.data.order.car
            )
            carsStore.selectCar(selectedCar)
          }

          orderStore.updateOrderId(response.data.data.order.id)
          initPayment(response.data.data.payment)
          if (response.data.data?.order?.priceChanged) {
            mainStore.updatePriceChanged(true)
          }
          if (priceChanged.value) {
            modalView.value = true
          }
        }
        isRequesting.value = false
      })
      .catch(function (error) {
        orderStore.$reset()
        carsStore.$reset()
        Sentry.captureException(error)
        isRequesting.value = false
        // If the order has already been paid
        if (error?.response?.status === 410) {
          router.push({ name: 'alreadyPaid' })
        }
        if (error?.response?.status === 404) {
          router.push({ name: 'home' })
        }
      })
  }
})
// Timer 5 min
// let now = new Date()
// let countdown = new Date(now.getTime() + 5 * 60000)
// storage.setItem('countdown', countdown, 'session')

onMounted(() => {
  paymentVisited.value = true

  let now = new Date()
  let countdown = new Date(now.getTime() + 5 * 60000)
  storage.setItem('countdown', countdown, 'session')
})
onBeforeUnmount(() => {
  priceChanged.value = false
})
</script>
<style>
.p-CardNumberInput-input {
  position: absolute !important;
  background: black !important;
}
</style>
